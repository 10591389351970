import React from 'react';
import styled from '@emotion/styled';
import { graphql, Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import SEO from 'components/SEO';
import { BREAKPOINTS, MOBILE_SIDE_PADDING, WEB_SIDE_PADDING } from 'utils/constants';

const Container = styled.div({
  padding: `206px ${WEB_SIDE_PADDING}`,
  [BREAKPOINTS[800]]: {
    padding: `206px ${MOBILE_SIDE_PADDING}`,
  },
});

const TitleContainer = styled.div((props) => ({
  marginBottom: 80,
  color: props.theme.colors.white,
}));

const List = styled.ul({
  fontSize: 15,
  padding: '3px 20px',
});

const ListItem = styled.li((props) => ({
  marginBottom: 16,
  '::marker': {
    color: props.theme.colors.white,
  },
}));

const StyledLink = styled(Link)((props) => ({
  fontSize: 16,
  fontWeight: 600,
  textDecoration: 'none',
  color: props.theme.colors.white,
}));

export const query = graphql`
  query LegalPageQuery {
    allPrismicLegalRootPage {
      nodes {
        dataRaw
      }
    }
  }
`;

const Legal = ({ data }) => {
  const pageData = data?.allPrismicLegalRootPage.nodes[0].dataRaw;

  return (
    <>
      <SEO
        pageTitle={pageData.page_title && RichText.asText(pageData.page_title)}
        ogTitle={pageData.og_title && RichText.asText(pageData.og_title)}
        twitterTitle={pageData.twitter_title && RichText.asText(pageData.twitter_title)}
        pageDescription={pageData.page_description && RichText.asText(pageData.page_description)}
        ogDescription={pageData.og_description && RichText.asText(pageData.og_description)}
        twitterDescription={
          pageData.twitter_description && RichText.asText(pageData.twitter_description)
        }
        pageImage={pageData?.page_image?.url}
        pageImageAlt={pageData?.page_image?.alt}
        twitterImage={pageData?.twitter_image?.url}
        twitterImageAlt={pageData?.twitter_image?.alt}
        ogImage={pageData?.og_image?.url}
        ogImageAlt={pageData?.og_image?.url?.alt}
      />
      <Container>
        <TitleContainer>
          <RichText render={pageData.title} />
        </TitleContainer>
        <List>
          {pageData.legal_links.map((link) => (
            <ListItem key={link.label}>
              <StyledLink to={link.link.uid}>{link.label}</StyledLink>
            </ListItem>
          ))}
        </List>
      </Container>
    </>
  );
};

export default Legal;
